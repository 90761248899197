import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import {
  mainWhite,
  mainBlue,
  mainBlack,
  larsseit,
  screen,
} from "../components/common/variables";
import AnimatedHeading from "../components/animated-heading/our-work-page";
import LabeledArrow from "../components/labeled-arrow";

const Wrapper = styled.div`
  .work {
    background-color: ${mainWhite};

    &__heading {
      color: ${mainBlack};
      font-family: ${larsseit};
      line-height: 1.23;
      font-size: 2.53rem;
      padding: 0 26px;
      @media ${screen.xsmall} {
        font-size: 3.3rem;
        display: block;
        padding: 0 60px;
      }
      @media ${screen.small} {
        font-size: 4rem;
        padding: 0 80px;
      }
      @media ${screen.medium} {
        font-size: clamp(1.4rem, calc(1.4rem + 3vw), 5.01rem);
        padding: 0 7vw;
      }

      .break {
        @media ${screen.xsmall} {
          display: block;
        }
      }
    }

    &__list {
      margin: 40px 0 0 0;
      @media ${screen.xsmall} {
        display: flex;
        flex-wrap: wrap;
        margin: 80px 8px 8px 8px;
      }
      @media ${screen.small} {
        margin: 90px 8px 8px 8px;
      }
      @media ${screen.xlarge} {
        margin: 120px 8px 8px 8px;
      }

      .each-project {
        overflow: hidden;
        margin: 8px;
        @media ${screen.xsmall} {
          width: calc(50% - 16px);
          margin: 8px;
        }
        @media ${screen.small} {
          width: calc(33.33% - 12px);
          margin: 6px;
        }
        &__img {
          display: block;
          transition: transform 0.8s ease;

          img {
          }
        }

        @media ${screen.withCursor} {
          &:hover .each-project__img {
            transform: scale(1.08);
          }
        }

        &--contact {
          background: ${mainBlue};
          position: relative;
          cursor: pointer;

          .text {
            position: absolute;
            padding: 0 26px;
            top: 50%;
            transform: translateY(-50%);
            @media ${screen.medium} {
              top: 60px;
              padding: 0 60px 0 60px;
              transform: translateY(0);
            }

            &__heading {
              color: ${mainWhite};
              display: block;
              font-family: ${larsseit};
              font-size: 2.3rem;
              line-height: 1.2;
              @media ${screen.small} {
                font-size: 2.1rem;
              }
              @media ${screen.medium} {
                font-size: clamp(2rem, calc(0.6rem + 2vw), 3rem);
                max-width: 400px;
              }

              .break {
                display: block;
              }
            }

            &__link {
              margin-top: 30px;
              display: block;
              cursor: pointer;
              @media ${screen.medium} {
                margin-top: 45px;
              }
            }
          }
          img {
            visibility: hidden;
          }

          @media ${screen.withCursor} {
            &:hover .text__link .label {
              color: ${mainBlack};
            }
            &:hover .text__link .arrow-line {
              stroke: ${mainBlack};
            }
          }
        }
      }
    }
  }
`;
const OurWorkPage = () => {
  return (
    <Layout colourNavbar={mainWhite}>
      <SEO title="Our Work | Handsome Creative" />
      <Wrapper>
        <div className="work">
          <AnimatedHeading className="work__heading" />

          <ul className="work__list">
            <li
              className="each-project"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <Link
                id="australian-thermic-lance-company-case-study"
                to="/our-work/australian-thermic-lance-company/"
                aria-label="Go to case study page"
                className="each-project__img"
              >
                <StaticImage
                  src="../images/handsome-creative-project-ATLC.jpg"
                  alt="Handsome Creative Case Study - Australian Thermic Lance Company"
                />
              </Link>
            </li>
            <li
              className="each-project"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <Link
                id="source-separation-systems-case-study"
                to="/our-work/source-separation-systems/"
                aria-label="Go to case study page"
                className="each-project__img"
              >
                <StaticImage
                  src="../images/handsome-creative-project-source-separation-systems.jpg"
                  alt="Handsome Creative Case Study - Source Separation Systems"
                />
              </Link>
            </li>
            <li
              className="each-project"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <Link
                id="apw-partners-case-study"
                to="/our-work/apw-partners/"
                className="each-project__img"
                aria-label="Go to case study page"
              >
                <StaticImage
                  src="../images/handsome-creative-project-apw-partners.jpg"
                  alt="Handsome Creative Case Study - APW Partners"
                />
              </Link>
            </li>
            <li
              className="each-project"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <Link
                id="motionthat-case-study"
                to="/our-work/motionthat/"
                className="each-project__img"
                aria-label="Go to case study page"
              >
                <StaticImage
                  src="../images/handsome-creative-project-motionthat.jpg"
                  alt="Handsome Creative Case Study - MotionThat"
                />
              </Link>
            </li>

            <li
              className="each-project each-project--contact"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <a
                href="mailto:team@hellohandsome.com.au"
                aria-label="Send an email now"
              >
                <div>
                  <div className="text">
                    <span className="text__heading">
                      Insert your next{" "}
                      <span className="break">project here.</span>
                    </span>
                    <span className="text__link">
                      <LabeledArrow
                        label="GET IN TOUCH TODAY"
                        url="/about"
                        btn="true"
                      />
                    </span>
                  </div>
                  <StaticImage
                    src="../images/handsome-creative-project-ATLC.jpg"
                    alt="Handsome Creative Case Study - Australian Thermic Lance Company"
                  />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default OurWorkPage;
